<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1">Management Of Desktops By Microsoft SCCM</h2>
      <p class="desc">
        Microsoft SCCM is very efficient for IT software management. It handles
        the desktops and all the apps related to it. But as we know, everything
        has advantages but has flaws too same is the situation with Microsoft
        SCCM. It cannot entertain non-Microsoft apps. So, this creates a problem
        for many executives as they have to work with different kinds of tools
        to upgrade their business apps in a particular network. So, it creates
        very complexities and time taking.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/it-software-management/it-security/automate-third-party.jpg"
        alt="Management Of Desktops By Microsoft SCCM"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "eleventh-itSec-section",
};
</script>
