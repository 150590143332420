<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1">
        The Inspection Of File Servers And The Reporting Tools
      </h2>
      <ul>
        <li>
          <p>
            Web-based and real-time windows offer inspection and storage stuff
            through which data security is increased, information is managed
            effectively and fulfills the needs of compliance.
          </p>
        </li>
        <li>
          <p>
            By approaching the anomalies recording access patterns, inspecting
            the share, and NTFS permission, it monitors and examines the access
            to all folders and files.
          </p>
        </li>
        <li>
          <p>
            The storage capacity is enhanced by separating the old, unused, and
            unnecessary files, acquiring every bit of information about the
            space and the properties of files and folders.
          </p>
        </li>
        <li>
          <p>
            Remains active all the time and responds to all the violations
            regarding security through Email alerts.
          </p>
        </li>
        <li>
          <p>
            Agrees with [_SOX_], [_HIPAA_], [_FISMA_], [_PCI_], [_GLBA_],
            [_GDPR_], and other regulatory mandates.
          </p>
        </li>
      </ul>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/it-software-management/it-security/audit-and-analyze.jpg"
        alt="The Inspection Of File Servers And The Reporting Tools"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "fivteen-itSec-section",
};
</script>
