<template>
  <LeftSection>
    <div class="info-desc col-lg-8 pt-3">
      <h2 class="title-yellow h1">
        To Inspect And Report A Change In Exchange Server
      </h2>
      <ul>
        <li>
          <p>
            Inspecting change, web-based, and reporting solutions are there for
            MS replaced environment.
          </p>
        </li>
        <li>
          <p>
            The structure and stock of smart gadgets are actively synced by the
            tracking and monitoring firms.
          </p>
        </li>
        <li>
          <p>
            Reports regarding outlook web access usage, mailbox traffic, and
            development are reported.
          </p>
        </li>
        <li>
          <p>
            It is also compatible with custom made reports in which different
            data filters, auto-scheduling, and different formats are used.
          </p>
        </li>
        <li>
          <p>
            It provides the inspecting tool through which you can easily inspect
            the unlicensed mailbox and other changes.
          </p>
        </li>
        <li>
          <p>Handling And Reporting The Active Directory.</p>
        </li>
        <li>
          <p>
            Web-based, active directory handler tool is provided through which
            you can manage your mobile phones through I phone or Android
            applications.
          </p>
        </li>
        <li>
          <p>
            It provides a single-comfort active directory and exchanges the
            executives.
          </p>
        </li>
        <li>
          <p>
            It is also compatible with templates, and a comma-separated values
            file user.
          </p>
        </li>
        <li>
          <p>
            It includes a huge number of reports concerning safety, Sarbanes
            Oxley Act—-Health-Insurance-Portability, and the act of
            accountability compliance or distribution reports.
          </p>
        </li>
        <li>
          <p>
            It also supports the role and OU based representative of front desk
            work.
          </p>
        </li>
        <li>
          <p>
            It also provides detail of user management, automated AD cleaner,
            and other jobs.
          </p>
        </li>
      </ul>
    </div>
    <div class="info-img col-lg-4 text-center">
      <img
        class="w-100"
        src="../../../../assets/it-software-management/it-security/circular-logging.jpg"
        alt="To Inspect And Report A Change In Exchange Server"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "fivth-itSec-section",
};
</script>
