<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1">Network Configuration Management(NCM)</h2>
      <p class="desc">
        Network Configuration Manager is a multi-purpose network change,
        configuration, and compliance Management[NCCCM]. It proposes solutions
        for all kinds of devices, including routers and firewalls. NCM helps in
        solving all kinds of problems by controlling every activity of the
        device.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/it-software-management/it-security/ncm-change.jpg"
        alt="Network Configuration Management(NCM)"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "nineth-itSec-section",
};
</script>
