<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1">
        Firewall Security and Configuration Management
      </h2>
      <p class="desc">
        Acquiring a bit of information about network activity and keeping up to
        date about firewall logs can be a difficult and tiring job because the
        security tool usually generates a huge amount of logs. To solve this
        problem, we have introduced a tool known as Firewall Analyzer. It is
        software that generates less but important logs that help the executives
        to get information about bandwidth usage. Moreover, it is a seller
        rationalist and is compatible with all commercialized network firewalls
        consisting of Check Point, Cisco, Juniper, Fortinet, Palo alto, and many
        more.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/it-software-management/it-security/compliance-standards-v5.jpg"
        alt="Firewall Security and Configuration Management"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "first-itSec-section",
};
</script>
