<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1">
        Information About Patch Windows, Mac, Linux, and third-party
      </h2>
      <p class="desc">
        The following are the key points about patch windows, MAC and Linux.
      </p>
      <ul>
        <li>
          <p>
            Patch computers are available in networks like LAN, WAN, roaming
            users, and DMZ.
          </p>
        </li>
        <li>
          <p>Computerize the patches from recognition to categorization.</p>
        </li>
        <li>
          <p>Authorize the patches by testing it.</p>
        </li>
        <li>
          <p>
            They bring into play the patches of apps when they are in the
            stationary phase that is no one is using them
          </p>
        </li>
        <li>
          <p>
            To save time from wasting it always installs them after the business
            hours or office timings.
          </p>
        </li>
        <li>
          <p>
            Turns on the gadgets before its effective use and restart them
            automatically after the installation of patches.
          </p>
        </li>
        <li>
          <p>Do not categorize the patches into groups and sections.</p>
        </li>
        <li>
          <p>Inspects and then reports about the use of patches.</p>
        </li>
      </ul>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/it-software-management/it-security/patch-manager.jpg"
        alt="Information About Patch Windows, Mac, Linux, and third-party"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "thirteen-itSec-section",
};
</script>
