<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1">Active Directory Password Management</h2>
      <ul>
        <li>
          <p>
            Simplifies password management with self-service for end-users while
            ensuring tight security using multi-factor authentication.
          </p>
        </li>
        <li>
          <p>
            Implements granular password policies across on-premises Active
            Directory and cloud applications.
          </p>
        </li>
        <li>
          <p>
            Automatically synchronizes passwords to securely unify identities
            across multiple platforms.
          </p>
        </li>
        <li>
          <p>
            Provides seamless and secure access to cloud applications with
            Active Directory-based single sign-on.
          </p>
        </li>
        <li>
          <p>
            Notifies users periodically via email, SMS, and push notifications
            to prevent password expiration.
          </p>
        </li>
      </ul>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/it-software-management/it-security/password-policy.jpg"
        alt="Active Directory Password Management"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "seventh-itSec-section",
};
</script>
