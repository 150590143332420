<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1">
        To Inspect And Report A Change in Active Directory
      </h2>
      <p class="desc">
        It is ensured that in no time, the resources, for instance, the Domain
        Controllers are observing, supervising, and reporting the whole
        information about the active directory.
      </p>
      <p class="desc">
        Clients, team, [GPO], system, [OU], [DNS], AD Scheme, and Configuration
        are changed from [GUI]reports and emails.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/it-software-management/it-security/reports-domain.jpg"
        alt="Build Solitary Optimized Computing Batches"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "fourth-itSec-section",
};
</script>
