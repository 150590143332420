<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1">Management Of Cloud Security & Log</h2>
      <p class="desc">
        There is an IT security manager known as Cloud security Plus that
        manages the cloud and protects it. With this IT security management, you
        can see your cloud into both AWS and Azure cloud structure. You can
        easily acquire information about your cloud activities as it gives you a
        detailed report and alerts about everything happening in your cloud. So,
        by using this IT manager, you don’t have to worry about anything as it
        facilitates you by upgrading your business.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/it-software-management/it-security/cloud-management.jpg"
        alt="Management Of Cloud Security & Log"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "twelve-itSec-section",
};
</script>
