<template>
  <it-security />
</template>

<script>
import ItSecurity from "../../components/solutions/it-software-management/ItSecurity.vue";
export default {
  name: "it-security-page",
  components: { ItSecurity },
};
</script>
