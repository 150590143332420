<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1">
        The Security And Passivity Of MicrosoftOffice-365
      </h2>
      <p class="desc">
        There is an office manager known as O365 Manager Plus, which inspects
        and reports the solutions of any problem in Office 365 so that the
        executives can easily manage their Office 365 setup. The easy to use and
        web-based computer program helps you handle Azure-active-directory,
        Skype, and the One drive for business purposes, all from one place.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/it-software-management/it-security/mailboxes.jpg"
        alt="The Security And Passivity Of MicrosoftOffice-365"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "eightth-itSec-section",
};
</script>
