<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1">
        Coordinated Desktop and Mobile Device Management
      </h2>
      <p class="desc">
        Desktop Central is a coordinated desktop and cell phone handling program
        that helps in overseeing the servers, laptops, PC’s, cell phones, and
        tablets from a focal area.
      </p>
      <p class="desc">
        Automate your normal desktop handling schedules like introducing
        patches, dispersing programs, dealing with your IT Assets, overseeing
        software licenses, checking software by using measurements, overseeing
        USB gadget use, assuming responsibility for remote desktop, and more
        integrated Desktop and Mobile Device Management.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/it-software-management/it-security/centralized-password-vault4-2.jpg"
        alt="Coordinated Desktop and Mobile Device Management"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "tenth-itSec-section",
};
</script>
