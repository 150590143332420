<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1">SIEM and The Network Threat</h2>
      <ul>
        <li>
          <p>
            SIEM solutions are provided whenever the network of firms weakens.
          </p>
        </li>
        <li>
          <p>
            They find, examine the logs from focal locations, and then provide a
            detailed report on it. Moreover, it also helps in archiving those
            logs.
          </p>
        </li>
        <li>
          <p>
            Supervises the directory environment, keeps records of all the
            occasions, including the client, team, _PC_, _GPO_, and _OU_
            changes.
          </p>
        </li>
        <li>
          <p>
            Inspects the fileServers of windows, failover clusters, NetApp for
            file modifications, sharing approach, and authorization.
          </p>
        </li>
        <li>
          <p>
            Provides the client, the conditions to all the network activities to
            weaken the internal errors.
          </p>
        </li>
        <li>
          <p>
            Weakens the internal issues by inspecting all the networking
            gadgets, databases, servers of apps, and effective solutions for
            errors.
          </p>
        </li>
        <li>
          <p>
            Cloud security tools like Amazon web services(AWS) and Microsoft
            Azure that helps in keeping a record of all the events happening in
            cloud platforms.
          </p>
        </li>
      </ul>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/it-software-management/it-security/siem.jpg"
        alt="SIEM and The Network Threat"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "fourteen-itSec-section",
};
</script>
