<template>
  <RightSection>
    <div class="info-desc col-lg-8 pt-3">
      <h2 class="title-yellow h1">Exclusive Management Of Passwordst</h2>
      <p class="desc">
        This type of IT security management is used to manage different networks
        and internet traffic. They are also used in the detection of
        application-specific attacks, thereby decreasing it. It can be either
        hardware-based or software-based with specific policies and a mixture of
        protocols and ports that damages the seventh layer. Many different
        characteristics are offered by next-generation firewalls(NGFW),
        including Stateful investigation, application’s understanding, knowledge
        about the user, Intrusion Protection System, multi-purpose modes, and
        capacity to use the latest updates from insight resources.
      </p>
    </div>
    <div class="info-img col-lg-4 mb-0 mb-sm-3 text-center">
      <img
        class="w-100"
        src="../../../../assets/it-software-management/it-security/centralized-password-vault4.jpg"
        alt="Exclusive Management Of Passwordst"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "second-itSec-section",
};
</script>
