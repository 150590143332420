<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1">Handling of Passwords Of Active Directory</h2>
      <ul>
        <li>
          <p>
            It simplifies the passwords handling with the self-administration
            for valuable clients guaranteeing the high level of safety utilizing
            numerous word validation.
          </p>
        </li>
        <li>
          <p>
            It also applies strict password strategies on active directory.
            Also, on all the cloud apps.
          </p>
        </li>
        <li>
          <p>
            It automatically runs passwords at the same time on different
            devices so that the user won’t have to face any sort of difficulty.
          </p>
        </li>
        <li>
          <p>
            Through this, you can have coherent and flawless access to all the
            applications of cloud and active directory-based.
          </p>
        </li>
        <li>
          <p>
            It always informs the user about any changes, including password
            access or changes through emails or messages.
          </p>
        </li>
      </ul>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/it-software-management/it-security/last-logon.jpg"
        alt="Handling of Passwords Of Active Directory"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "sixth-itSec-section",
};
</script>
