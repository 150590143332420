<template>
  <LeftSection>
    <div class="info-desc col-lg-8 mt-5">
      <h2 class="title-orange h1">
        Secure Shell(SSH)Key and Secure Sockets Layer Certificate Management
      </h2>
      <p class="desc">
        Manage engine Key Manager Plus is an IT software management solution
        that helps you in strengthen, handle, supervise, and analyze the lives
        of SSH and SSL certificates. They also help in monitoring the SSH and
        SSL environment and giving full control to executives so that they can
        prevent any kind of violation and agreement issues.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/it-software-management/it-security/expiration-alerts.jpg"
        alt="Secure Shell(SSH)Key and Secure Sockets Layer Certificate Management"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "third-itSec-section",
};
</script>
